import { useQuery } from '@apollo/client'
import {
  InvoicesOnePlaceEmptyState,
  InvoicesOnePlaceNotPaidState,
  TextInput,
} from '@getjelly/jelly-ui'
import { IconSearch } from '@tabler/icons-react'
import { CSSProperties, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import {
  PendingInvoiceBaseFragment,
  PendingInvoice,
  PendingInvoiceGroupedConnection,
  QueryAllInvoiceListArgs,
  Invoice,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { SupplierSelect } from 'components'
import { VirtualizedSectionList } from 'components/newUi'
import { useDebounce, useIsPaidKitchen } from 'hooks'
import PendingInvoiceRowItem from 'screens/Spending/Invoices/components/RowItems/PendingInvoiceRowItem'
import { allInvoiceList } from 'screens/Spending/Invoices/graphql/allInvoiceList'

import { DocumentTypeSelect } from './components/DocumentTypeSelect'
import { InvoiceRowItem } from './components/RowItems/InvoiceRowItem'
import { groupInvoices } from './groupInvoices'

import { ApprovedInvoiceSkeleton } from '../../../components/Skeleton/Invoice/ApprovedInvoiceSkeleton'
import { GroupedInvoicesSkeleton } from '../../../components/Skeleton/Invoice/GroupedInvoicesSkeleton'
import { selectDocumentType } from '../../../store/documentType'
import { selectCounts } from '../../../store/kitchen'
import { selectSupplier } from '../../../store/supplier'

type InvoiceGroup = { label: string; items: PendingInvoiceBaseFragment[] }

export function AllInvoicesScreen() {
  const { selectedKitchen } = useKitchen()
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('query') ?? ''
  const isFeatureAvailable = useIsPaidKitchen()

  const counts = useSelector(selectCounts())
  const documentType = useSelector(selectDocumentType())
  const selectedSupplier = useSelector(selectSupplier())

  const debouncedSearch = useDebounce(search, 500)

  const { data, loading, fetchMore, client } = useQuery<
    { allInvoiceList: PendingInvoiceGroupedConnection },
    QueryAllInvoiceListArgs
  >(allInvoiceList, {
    fetchPolicy: 'network-only',
    skip: !selectedKitchen,
    variables: {
      documentType,
      kitchenCursor: selectedKitchen?._cursor ?? '',
      searchTerm: debouncedSearch,
      skip: 0,
      supplierId: selectedSupplier?.id ?? 0,
      take: 20,
    },
  })

  useEffect(() => {
    client.cache.evict({ fieldName: 'allInvoiceList' })
  }, [])

  const sortedData: InvoiceGroup[] = useMemo(() => {
    if (!data?.allInvoiceList) {
      return []
    }

    return groupInvoices(data.allInvoiceList)
  }, [data])

  if (!isFeatureAvailable) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <InvoicesOnePlaceNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (
    !debouncedSearch &&
    counts.invoiceCount === 0 &&
    (data?.allInvoiceList.count ?? 0) === 0
  ) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <InvoicesOnePlaceEmptyState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="h-12 flex items-center bg-primary-50">
        <DocumentTypeSelect />
        <SupplierSelect title="Spending" />
      </div>

      <div className="p-4 pt-0 bg-primary-50">
        <TextInput
          icon={IconSearch}
          placeholder="Search by document number"
          value={search}
          onChange={(value) => {
            setSearchParams(
              { query: value?.toString() ?? '' },
              { replace: true },
            )
          }}
        />
      </div>

      {!data && (
        <GroupedInvoicesSkeleton
          invoiceSkeleton={<ApprovedInvoiceSkeleton />}
        />
      )}

      {data && (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <VirtualizedSectionList
            pageInfo={data.allInvoiceList.pageInfo}
            ROW_HEIGHT={({ invoice, status }) => {
              if (status === 'pending' || status === 'processing') {
                return 95
              }

              if (!invoice) return 95

              return !(invoice as Invoice).approved && invoice.invoiceTotal
                ? 113
                : 95
            }}
            Item={(props: {
              data: InvoiceGroup
              index: number
              isVisible: boolean
              style: CSSProperties
            }) => {
              // add handleInvoiceSelect handler to Item
              const pendingInvoice = props.data.items[
                props.index
              ] as PendingInvoice
              return pendingInvoice.invoice ? (
                <InvoiceRowItem
                  pendingInvoiceId={pendingInvoice.id}
                  notes={pendingInvoice.notes}
                  invoice={pendingInvoice.invoice}
                  {...props}
                />
              ) : (
                <PendingInvoiceRowItem invoice={pendingInvoice} {...props} />
              )
            }}
            data={sortedData}
            loading={loading}
            fetchMore={() => {
              if (!loading) {
                fetchMore({
                  variables: {
                    skip: data?.allInvoiceList.nodes?.length,
                    take: 20,
                  },
                })
              }
            }}
          />
        </div>
      )}
    </>
  )
}
