import { Typography } from '@getjelly/jelly-ui'
import { IconChevronRight } from '@tabler/icons-react'
import { isEmpty, isNil } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { ListRowProps } from 'react-virtualized'

import { Dish, Recipe } from 'api'
import { buildViewRoute } from 'routes/Paths'
import { Image } from 'screens/Invoices/Add'

type Props = { data: Recipe[] } & ListRowProps

export function Item({ index, style, data }: Props) {
  const navigate = useNavigate()

  const item = data[index]
  const isDish = !isNil(item.dishList.nodes) && !isEmpty(item.dishList.nodes)
  const rowData: Recipe | Dish = isDish ? item.dishList.nodes[0] : item

  const handleNavigate = () => {
    navigate(buildViewRoute(rowData.id, isDish))
  }

  return (
    <div
      data-testid={`item_${rowData.name}`}
      onClick={handleNavigate}
      className="bg-white border-b border-primary-100 cursor-pointer flex"
      style={style}
    >
      <div className="w-16 h-16 shrink-0">
        <Image
          localImage={false}
          url={rowData.imageUrl ?? undefined}
          frame={false}
          className="w-16 h-16"
        />
      </div>

      <div className="flex-1 flex px-3 space-x-2">
        <div className="flex-1 flex items-center justify-between space-x-2 line-clamp-1">
          <div className="flex items-center">
            <Typography
              style="subtitle1"
              className="text-primary-900 capitalize line-clamp-2"
            >
              {rowData.name}
            </Typography>
          </div>

          <div className="flex items-center text-primary-600 capitalize">
            <Typography style="subtitle1" className="text-primary-400">
              {rowData.isDraft === false ? '' : 'DRAFT'}
            </Typography>
          </div>
        </div>

        <div className="flex items-center shrink-0">
          <IconChevronRight />
        </div>
      </div>
    </div>
  )
}
