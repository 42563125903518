import { Ingredient, Recipe } from 'api'
import { HighlightText } from 'components/newUi'
import { Icon } from 'components/newUi/Icon'
import { Typography } from 'components/newUi/Typography'
import { useTheme } from 'styles/newUi'
import { formatAsCurrency, minimumCost } from 'utils/form/price'

type Props = {
  search: string
  item: Recipe | Ingredient
  handleOnClick: (item: Recipe | Ingredient) => void
}

export function Item({ item, search, handleOnClick }: Props) {
  const { theme } = useTheme()
  let costDescription = undefined

  if (item.__typename === 'Recipe') {
    costDescription = {
      amount: `${item.amount} ${item.unit?.name || 'N/A'}`,
      cost: formatAsCurrency(minimumCost(item ? item.unitCost! : 0)),
    }
  }

  if (item.__typename === 'Ingredient') {
    costDescription =
      item.product?.packSize && item.product?.packSize !== 1
        ? (costDescription = {
            amount: `${item.product?.packSize} x ${item.product?.unitValue} ${
              item.product?.unit.name || 'N/A'
            }`,
            cost: `${
              item.price
                ? formatAsCurrency(item.price ?? item.product?.price ?? 0)
                : 'No Price'
            }`,
          })
        : {
            amount: ` ${item.product?.unitValue} ${
              item.product?.unit.name || 'N/A'
            }`,
            cost: `${
              item.price
                ? formatAsCurrency(item.price ?? item.price ?? 0)
                : 'No Price'
            }`,
          }
  }

  return (
    <div
      role="button"
      className="select-ingredient-list-item"
      data-testid="select-ingredient-list-item"
      tabIndex={0}
      onKeyDown={() => {
        //do nothing
      }}
      onClick={() => handleOnClick(item)}
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
        cursor: 'pointer',
        display: 'grid',
        gap: '0 5px',
        gridTemplateColumns: '1fr 0fr 40px',
        height: 60,
        paddingLeft: theme.spacing(2),
        whiteSpace: 'nowrap',
        width: '100%',
      }}
    >
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 500,
            marginBottom: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          <HighlightText
            //@ts-expect-error
            text={item?.product?.name ?? item?.name}
            search={search}
          />
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: theme.palette.primary[60].toHexString(),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {
            //@ts-expect-error
            item?.product?.supplier?.name?.toLowerCase()
          }
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 500, marginBottom: 4 }}
        >
          {costDescription?.cost}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: theme.palette.primary[60].toHexString() }}
        >
          {costDescription?.amount}
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'center',
          color: theme.palette.secondary[100].toHexString(),
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Icon iconName="addCircle" />
      </div>
    </div>
  )
}
