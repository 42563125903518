import { Typography } from '@getjelly/jelly-ui'

type Props = {
  active?: boolean
  disabled?: boolean
  label?: string
  onClick?: () => void
}

export function Radio({ active, disabled, label, onClick }: Props) {
  const textColor = disabled ? 'text-primary-200' : 'text-primary-800'
  const pointer = disabled ? 'cursor-not-allowed' : 'cursor-pointer'
  const circleBg = active
    ? disabled
      ? 'bg-primary-200'
      : 'bg-secondary-400'
    : ''

  function click() {
    if (disabled) return
    onClick?.()
  }

  return (
    <div onClick={click} className={`flex items-center space-x-1 ${pointer}`}>
      <div className="w-6 h-6 flex items-center justify-center border-2 border-primary-100 bg-white rounded-full">
        <div className={`w-3.5 h-3.5 rounded-full ${circleBg}`} />
      </div>

      <Typography style="caption" className={textColor}>
        {label}
      </Typography>
    </div>
  )
}
