import { useMutation, useApolloClient } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import {
  PieChart as PieChartIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material'
import clsx from 'clsx'
import { Formik } from 'formik'
import { isEmpty, isNil } from 'ramda'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import {
  Dish,
  Recipe,
  MutationDeleteOneRecipeArgs,
  MutationDeleteOneDishArgs,
  Mutation,
} from 'api'
import {
  Container,
  NewTextField,
  ShoppingBasketIcon,
  SelectSection,
} from 'components'
import { Checkbox } from 'components/Checkbox'
import {
  NewButton,
  NewLayout,
  Icon,
  Typography,
  LeaveConfirmationModal,
} from 'components/newUi'
import { SelectDishType } from 'components/SelectList/SelectDishType'
import { useWindowSize } from 'hooks'
import { buildViewRoute, routes } from 'routes/Paths'
import { useUpdateComponent } from 'screens/Create/Ingredients/hooks'
import { useTheme } from 'styles/newUi'
import { cleanErrorMessage, logEvent, noAllergenData } from 'utils'

import { useStyles } from './styles'

import { errorToast, successToast } from '../../../components/toasts'
import { deleteRecipeMutation, deleteDishMutation } from '../graphql'
import { ImageContainer } from '../ImageContainer'

const validationSchema = Yup.object({
  imageUrl: Yup.string().nullable(),

  isDish: Yup.bool(),
  name: Yup.string().required('Required'),
  section: Yup.string().when('isDish', {
    is: (value: string) => value,
    otherwise: Yup.string().required('Required').notOneOf(['Section']),
    then: Yup.string().nullable(),
  }),

  type: Yup.string().when('isDish', {
    is: (value: string) => value,
    otherwise: Yup.string().nullable(),
    then: Yup.string().required('Required'),
  }),
})

export interface IProps {
  setCurrentPage: (num: number) => void
  recipe: Recipe
  dish?: Dish
}

export const Details = ({ setCurrentPage, recipe, dish }: IProps) => {
  const [updateComponent, loading] = useUpdateComponent(!!dish)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isInitialDraft, setIsInitialDraft] = useState<boolean>(true)
  const [isCalcDraft, setIsCalcDraft] = useState<boolean>(true)
  const navigate = useNavigate()
  const { id } = useParams()
  const { theme } = useTheme()
  const { cache } = useApolloClient()
  const { gte: isDesktop } = useWindowSize('md')

  const classes = useStyles()
  const [ingredientsError, setIngredientsError] = useState(false)
  const [pricingError, setPricingError] = useState(false)

  const [deleteRecipe] = useMutation<
    { deleteOneRecipe: Mutation['deleteOneRecipe'] },
    MutationDeleteOneRecipeArgs
  >(deleteRecipeMutation, {
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const normalizedId = cache.identify({
        __typename: 'Recipe',
        id: data.deleteOneRecipe.id,
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
    refetchQueries: ['getRecipeById', 'recipeList'],
  })

  const [deleteDish] = useMutation<
    { deleteOneDish: Mutation['deleteOneDish'] },
    MutationDeleteOneDishArgs
  >(deleteDishMutation, {
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const normalizedId = cache.identify({
        __typename: 'Dish',
        id: data.deleteOneDish?.id,
      })

      const dishId = cache.identify({
        __typename: 'Recipe',
        id: data.deleteOneDish?.recipe?.id,
      })

      cache.evict({ id: normalizedId })
      cache.evict({ id: dishId })
      cache.gc()
    },
    refetchQueries: ['getDishById', 'getRecipeById', 'recipeList'],
  })

  useEffect(() => {
    setIsInitialDraft(dish ? dish.isDraft ?? true : recipe?.isDraft ?? true)
  }, [dish, recipe])

  const totalCost = recipe.unitCost
  const costPerPortion: number = Number(totalCost) / (dish?.portion ?? 1)

  const ingredientsWarning =
    (isEmpty(recipe?.ingredients) && isEmpty(recipe?.childRecipes)) ||
    (dish && (isNil(dish?.portion) || dish?.portion === 0)) ||
    (!dish &&
      isNil(recipe?.amount || recipe?.amount === 0) &&
      isNil(recipe?.unit))
      ? classes.warning
      : undefined
  const allergensWarning = noAllergenData(recipe) ? classes.warning : undefined
  const methodWarning = !recipe?.instructions ? classes.warning : undefined
  const costingWarning =
    isNil(dish?.price) || dish?.price === 0 ? classes.warning : undefined

  const checkIfDraft = () => {
    if (isEmpty(recipe?.ingredients) && isEmpty(recipe?.childRecipes))
      setIngredientsError(true)
    const isDish = dish ? true : false
    if (isDish) {
      if (
        dish?.gpTarget === undefined ||
        dish?.gpTarget === null ||
        // dish?.overheads === undefined ||
        // dish?.overheads === null ||
        dish?.price === undefined ||
        dish?.price === null ||
        dish?.vat === undefined ||
        dish?.vat === null
      )
        setPricingError(true)
      if (
        dish?.gpTarget === undefined ||
        dish?.gpTarget === null ||
        // dish?.overheads === undefined ||
        // dish?.overheads === null ||
        dish?.price === undefined ||
        dish?.price === null ||
        dish?.vat === undefined ||
        dish?.vat === null ||
        (isEmpty(recipe?.ingredients) && isEmpty(recipe?.childRecipes))
        // always null atm for recipe
        // recipe?.amount === undefined || recipe?.amount === null ||
        // recipe?.unit === undefined || recipe?.unit === null ||
        // recipe?.unitCost === undefined || recipe?.unitCost === null
      ) {
        setIsCalcDraft(true)
        errorToast(
          `To mark this ${
            dish ? 'dish' : 'recipe'
          } as complete, please add the missing information below`,
        )
      } else {
        setIsCalcDraft(!isCalcDraft)
        return
      }
    } else {
      if (
        recipe?.amount === undefined ||
        recipe?.amount === null ||
        recipe?.unit === undefined ||
        recipe?.unit === null ||
        recipe?.unitCost === undefined ||
        recipe?.unitCost === null
      ) {
        errorToast(
          `To mark this ${
            dish ? 'dish' : 'recipe'
          } as complete, please add the missing information below`,
        )
        setIsCalcDraft(true)
      } else {
        setIsCalcDraft(!isCalcDraft)
        return
      }
    }
  }

  const handleDelete = () => {
    const deleteFunction = dish ? deleteDish : deleteRecipe
    const entity = dish || recipe
    deleteFunction({
      variables: {
        id: Number(id),
      },
    })
      .then((response: any) => {
        if (response.data?.deleteOneDish || response.data?.deleteOneRecipe) {
          successToast(`${entity.name} has been deleted`)
        }

        logEvent('tap_button', {
          event_category: dish ? 'view_dish' : 'view_recipe',
          event_label: dish ? 'delete_dish' : 'delete_recipe',
        })
        navigate(`${routes.Book}${routes.List}`)
      })
      .catch((error) => errorToast(cleanErrorMessage(error.message)))
  }

  return (
    <>
      <Formik
        onSubmit={async (state) => {
          const { name, section, imageUrl, type } = state

          await updateComponent({
            variables: {
              data: {
                id: dish ? dish.id : recipe?.id,
                imageUrl,
                isDraft:
                  !dish?.isDraft || !recipe.isDraft ? false : isCalcDraft,
                name,
                section: !type ? section : undefined,
                type: type,
              },
            },
          })
            .then(({ data }) => {
              // @ts-ignore
              const { name } = (data?.updateOneRecipe ??
                // @ts-ignore
                data?.updateOneDish) as Recipe

              successToast(`Saved ${name}`)
              navigate(buildViewRoute(id as string, !!dish), { replace: true })
            })
            .catch((error) => {
              errorToast(cleanErrorMessage(error.message))
            })

          return
        }}
        validationSchema={validationSchema}
        initialValues={{
          imageUrl: recipe?.imageUrl ?? undefined,
          isDish: !!dish,
          name: recipe?.name ?? '',
          section: recipe?.section,
          type: dish ? dish?.type : undefined,
          unitId: recipe?.unit?.id ?? undefined,
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
            <NewLayout
              subtitle="Costing"
              title={dish ? 'Dish' : 'Recipe'}
              rightContent={
                <div className="px-4">
                  <Button
                    style="delete"
                    label="Delete"
                    onClick={() => setShowDeleteModal(true)}
                  />
                </div>
              }
              bottomContent={
                <div
                  style={{
                    borderTop: `solid 2px ${theme.palette.primary[10].toHexString()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingBottom: 15,
                    paddingTop: 15,
                  }}
                >
                  {isInitialDraft && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: isDesktop ? 'left' : undefined,
                        paddingLeft: 20,
                      }}
                    >
                      <Checkbox
                        style={{
                          marginRight: !isDesktop ? '24px' : 8,
                          padding: 0,
                        }}
                        color="primary"
                        checked={!isCalcDraft}
                        onClick={() => {
                          checkIfDraft()
                        }}
                      ></Checkbox>
                      <div>
                        <div style={{ fontWeight: 500 }}>Finish costing</div>
                        <Typography
                          variant="caption"
                          style={{ fontSize: '0.8rem' }}
                        >
                          + make available in dishes and recipes
                        </Typography>
                      </div>
                    </div>
                  )}
                  <NewButton
                    text={`Save ${dish ? 'Dish' : 'Recipe'}`}
                    disabled={formik.isSubmitting}
                    style={{
                      margin: 'auto',
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(2),
                      padding: 0,
                      paddingLeft: theme.spacing(2),
                      paddingRight: theme.spacing(2),
                      whiteSpace: 'nowrap',
                    }}
                    onClick={formik.handleSubmit}
                  />
                </div>
              }
            />
            <Container
              style={{
                display: 'grid',
                gridTemplateAreas: '"."".""."',
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr 56px',
                height: '100%',
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <div
                style={{ height: '100%' }}
                className={classes.detailContainer}
              >
                <div className={classes.photoContainer}>
                  <div className={classes.photoBox}>
                    <ImageContainer
                      loading={loading}
                      imageSource={formik.values.imageUrl}
                      altText={'Click to add a photo'}
                      setImageUrl={(value) => {
                        formik.setFieldValue('imageUrl', value)
                      }}
                      mode="edit"
                    />
                  </div>
                  <Container
                    style={{
                      backgroundColor: theme.palette.primary[10].toHexString(),
                      padding: 10,
                    }}
                  >
                    <NewTextField
                      placeholder="Enter your recipe name"
                      label="Name"
                      defaultValue={formik.values.name}
                      error={formik.errors.name}
                      onChange={(value) => formik.setFieldValue('name', value)}
                      inputProps={{
                        'data-hj-allow': '',
                        value: formik.values.name,
                      }}
                    />
                    {!!dish && (
                      <SelectDishType
                        includePortion={false}
                        includeCustom={false}
                        state={formik.values.type!}
                        error={!!formik.touched.type && !!formik.errors.type}
                        formHelperText={
                          formik.touched.type && formik.errors.type
                            ? formik.errors.type
                            : undefined
                        }
                        handleChange={({
                          value,
                        }: {
                          name: string
                          value: string
                        }) => formik.setFieldValue('type', value)}
                      />
                    )}
                    {!dish && (
                      <SelectSection
                        state={formik.values.section}
                        error={
                          !!formik.touched.section && !!formik.errors.section
                        }
                        handleChange={(value) => {
                          // @ts-ignore
                          formik.setFieldValue('section', value.value)
                        }}
                      />
                    )}
                  </Container>
                  {/* {isDesktop && (
                    <NewButton
                      text="Save"
                      disabled={formik.isSubmitting}
                      style={{ margin: 'auto' }}
                    />
                  )} */}
                </div>
                <div className={classes.rowContainer}>
                  <div
                    className={clsx([
                      classes.item,
                      ingredientsError ? classes.error : ingredientsWarning,
                    ])}
                    onClick={() => setCurrentPage(0)}
                    onKeyDown={() => ''}
                    role="row"
                    tabIndex={0}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      <ShoppingBasketIcon
                        className={clsx([
                          classes.icon,
                          ingredientsError ? classes.error : ingredientsWarning,
                        ])}
                      />
                      <Row
                        title={`${
                          ingredientsWarning ? 'Add ' : ''
                        } Ingredients`}
                        body={
                          ingredientsWarning
                            ? ''
                            : (
                                (recipe?.ingredients.length ?? 0) +
                                (recipe?.childRecipes.length ?? 0)
                              ).toString()
                        }
                        right={
                          ingredientsWarning
                            ? ''
                            : '£' +
                              (dish
                                ? costPerPortion.round(2)
                                : (totalCost ?? 0).round(2)
                              ).toString()
                        }
                        rightBody={
                          ingredientsWarning
                            ? ''
                            : dish
                            ? 'cost per serving'
                            : 'Total cost'
                        }
                      ></Row>
                    </div>
                    {ingredientsWarning ? (
                      <Icon iconName="add" style={{ paddingLeft: 5 }} />
                    ) : (
                      <ChevronRightIcon style={{ paddingLeft: 5 }} />
                    )}
                  </div>
                  <div
                    className={clsx([classes.item, methodWarning])}
                    onClick={() => setCurrentPage(1)}
                    onKeyDown={() => ''}
                    role="row"
                    tabIndex={0}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      <Icon
                        iconName="formatListBulleted"
                        className={clsx([classes.icon, methodWarning])}
                      />
                      <Row
                        title={`${
                          methodWarning ? 'Write ' : ''
                        } Method & Notes`}
                        body={recipe?.instructions || ''}
                      />
                    </div>
                    {methodWarning ? (
                      <Icon iconName="add" style={{ paddingLeft: 5 }} />
                    ) : (
                      <ChevronRightIcon style={{ paddingLeft: 5 }} />
                    )}
                  </div>
                  <div
                    className={clsx([classes.item, allergensWarning])}
                    onClick={() => setCurrentPage(2)}
                    onKeyDown={() => ''}
                    role="row"
                    tabIndex={0}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      <Icon
                        iconName="notInterested"
                        className={clsx([classes.icon, allergensWarning])}
                      />
                      <Row
                        title={`${allergensWarning ? 'Set ' : ''} Allergens`}
                        body={
                          recipe?.allergens.length
                            ? `${
                                recipe?.allergens.filter((a) => a.contains)
                                  .length
                              } allergens${
                                recipe?.allergens.find((a) => a.removable)
                                  ? `, ${
                                      recipe?.allergens.filter(
                                        (a) => a.removable,
                                      ).length
                                    } removable`
                                  : ''
                              }`
                            : ''
                        }
                      ></Row>
                    </div>
                    {allergensWarning ? (
                      <Icon iconName="add" style={{ paddingLeft: 5 }} />
                    ) : (
                      <ChevronRightIcon style={{ paddingLeft: 5 }} />
                    )}
                  </div>
                  {!!dish && (
                    <div
                      className={clsx([
                        classes.item,
                        pricingError ? classes.error : costingWarning,
                      ])}
                      onClick={() => setCurrentPage(3)}
                      onKeyDown={() => ''}
                      role="row"
                      tabIndex={0}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          width: '100%',
                        }}
                      >
                        <PieChartIcon
                          className={clsx([
                            classes.icon,
                            pricingError ? classes.error : costingWarning,
                          ])}
                        />
                        <Row
                          title={`${costingWarning ? 'Set ' : ''} Pricing`}
                          body={
                            costingWarning
                              ? ''
                              : dish.gpTarget.toFixed(2) + '% GP'
                          }
                          right={dish.price ? '£' + dish.price?.toFixed(2) : ''}
                          rightBody={dish.price ? 'Menu Price' : ''}
                        ></Row>
                      </div>
                      {costingWarning ? (
                        <Icon iconName="add" style={{ paddingLeft: 5 }} />
                      ) : (
                        <ChevronRightIcon style={{ paddingLeft: 5 }} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </form>
        )}
      </Formik>
      <LeaveConfirmationModal
        action="Yes, delete"
        block={false}
        title=" Are you sure you want to delete this item?"
        text=""
        onConfirm={handleDelete}
        onCancel={() => {
          setShowDeleteModal(false)
        }}
        show={showDeleteModal}
      />
    </>
  )
}

const Row: React.FunctionComponent<{
  title: string
  body?: string
  right?: string
  rightBody?: string
}> = ({ title, body, right, rightBody }) => {
  const classes = useStyles()
  return (
    <div className={classes.itemContainer}>
      <div>
        <div className={classes.title}>{title}</div>
        <Typography
          variant="caption"
          style={{
            fontWeight: 400,
            margin: 0,
            maxWidth: '60vw',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {body}
        </Typography>
      </div>
      <div
        style={{ alignItems: 'end', display: 'flex', flexDirection: 'column' }}
      >
        <div className={classes.title}>{right}</div>
        <Typography variant="caption" style={{ fontWeight: 400 }}>
          {rightBody}
        </Typography>
      </div>
    </div>
  )
}
