import { DocumentType } from '../../api'

export function documentTypeDisplay(documentType: DocumentType): string {
  const textMap: Record<DocumentType, string> = {
    [DocumentType.Invoice]: 'Invoice',
    [DocumentType.CreditNote]: 'Credit-note',
    [DocumentType.PurchaseOrder]: 'Purchase-order',
    [DocumentType.Statement]: 'Statement',
    [DocumentType.DeliveryNote]: 'Delivery-note',
  }

  return textMap[documentType]
}
