import { NumberInput } from '@getjelly/jelly-ui'
import { IconCurrencyPound } from '@tabler/icons-react'
import { useEffect, useState } from 'react'

type Props = {
  min?: number
  max?: number
  error?: string
  value: number | null
  onChange: (money: number | null) => void
  placeholder?: string
  loading?: boolean
  disabled?: boolean
}

export function MoneyInput({
  min,
  max,
  error,
  placeholder,
  value,
  onChange,
  loading,
  disabled,
}: Props) {
  const [localValue, setLocalValue] = useState(
    value === null ? '' : value.toFixed(2),
  )

  useEffect(
    () => setLocalValue(value === null ? '' : value.toFixed(2)),
    [value],
  )

  const handleBlur = () => {
    const parsed = parseFloat(localValue)

    if (Number.isNaN(parsed)) {
      onChange(null)
      setLocalValue('')
    } else {
      onChange(parsed)
      setLocalValue(parsed.toFixed(2))
    }
  }

  return (
    <NumberInput
      min={min}
      max={max}
      error={error}
      value={localValue}
      onChange={setLocalValue}
      onBlur={handleBlur}
      leftIcon={IconCurrencyPound}
      placeholder={placeholder}
      loading={loading}
      disabled={disabled}
    />
  )
}
