import { useEffect } from 'react'
import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'

import { AcceptInvite } from 'screens/AcceptInvite'
import { BookLayout } from 'screens/Book/BookLayout'
import { Create as CreateBook } from 'screens/Book/Create'
import { CreateDish } from 'screens/Book/CreateDish'
import { CreateRecipe } from 'screens/Book/CreateRecipe'
import { NewBook } from 'screens/Book/NewBook'
import { CreateComponent } from 'screens/Create'
import { Finance } from 'screens/Finance'
import { ForgotPassword } from 'screens/ForgotPassword'
import { Home } from 'screens/Home'
import { ListIngredients } from 'screens/Ingredient/List'
import { UpdateIngredient } from 'screens/Ingredient/Update'
import { FoodFlash } from 'screens/Insights/FoodFlash'
import { InsightLayout } from 'screens/Insights/InsightLayout'
import { PriceAlert } from 'screens/Insights/PriceAlert'
import { SalesMix } from 'screens/Insights/SalesMix'
import { SpendingHistory } from 'screens/Insights/Spend'
import { Add } from 'screens/Invoices/Add'
import { Kitchen } from 'screens/Kitchen'
import { ImportLayout } from 'screens/Kitchen/Import/ImportLayout'
import { Linked } from 'screens/Kitchen/Import/Linked'
import { Unlinked } from 'screens/Kitchen/Import/Unlinked'
import { Menus } from 'screens/Menu'
import { Allergens } from 'screens/Menu/Allergens'
import { CreateMenu } from 'screens/Menu/CreateMenu'
import { EditMenu } from 'screens/Menu/EditMenu'
import { ViewMenu } from 'screens/Menu/ViewMenu'
import { NewPassword } from 'screens/NewPassword'
import { Checkout } from 'screens/Order/Checkout'
import { OrderSupplierProducts } from 'screens/Order/Products'
import { OrderSupplier } from 'screens/Order/Supplier'
import { OrderSupplierAdd } from 'screens/Order/Supplier/Add'
import { Basket as OrderSupplierBasket } from 'screens/Order/Supplier/Basket'
import { OrderHistory } from 'screens/OrderHistory'
import { OrderHistoryItem } from 'screens/OrderHistory/Item'
import { OrderingLayout as Ordering } from 'screens/Ordering'
import { CreateUpdate as CreateProduct } from 'screens/Product/CreateUpdate'
import { Products as ProductList } from 'screens/Product/List'
import { Products } from 'screens/Products'
import { Costing } from 'screens/Products/Costing'
import { OrderHistory as ProductOrderHistory } from 'screens/Products/OrderHistory'
import { PriceHistory } from 'screens/Products/PriceHistory'
import { ProductLayout } from 'screens/Products/ProductLayout'
import { ResetPassword } from 'screens/ResetPassword'
import { SetJobResponsibilities } from 'screens/SetJobResponsibilities'
import { SetJobRoles } from 'screens/SetJobRoles'
import { SetPassword } from 'screens/SetPassword'
import { SetProfilePicture } from 'screens/SetProfilePicture'
import { Settings } from 'screens/Settings'
import { Addresses } from 'screens/Settings/Addresses'
import { AddAddress } from 'screens/Settings/Addresses/Add'
import { EditAddress } from 'screens/Settings/Addresses/Edit'
import { Integrations } from 'screens/Settings/Integrations'
import { EposNowCategories } from 'screens/Settings/Integrations/EposNow/EposNowCategories'
import { EposNowConnect } from 'screens/Settings/Integrations/EposNow/EposNowConnect'
import { EposNowInstall } from 'screens/Settings/Integrations/EposNow/EposNowInstall'
import { EposNowInstalled } from 'screens/Settings/Integrations/EposNow/EposNowInstalled'
import { EposNowIntro } from 'screens/Settings/Integrations/EposNow/EposNowIntro'
import { EposNowLocationSelect } from 'screens/Settings/Integrations/EposNow/EposNowLocationSelect'
import { SquareCallback } from 'screens/Settings/Integrations/Square/SquareCallback'
import { SquareCategories } from 'screens/Settings/Integrations/Square/SquareCategories'
import { SquareInstall } from 'screens/Settings/Integrations/Square/SquareInstall'
import { SquareInstalled } from 'screens/Settings/Integrations/Square/SquareInstalled'
import { SquareIntro } from 'screens/Settings/Integrations/Square/SquareIntro'
import { SquareLocationSelect } from 'screens/Settings/Integrations/Square/SquareLocationSelect'
import { Locations } from 'screens/Settings/Locations'
import { Me } from 'screens/Settings/Me'
import { MeEdit } from 'screens/Settings/Me/edit'
import { PlanDetails } from 'screens/Settings/Plans/details'
import { Plans } from 'screens/Settings/Plans/list'
import { Refer } from 'screens/Settings/Refer'
import { Team } from 'screens/Settings/Team'
import { AddTeam } from 'screens/Settings/Team/AddTeam'
import { EditTeam } from 'screens/Settings/Team/EditTeam'
import { SignIn } from 'screens/SignIn'
import { SignUp } from 'screens/SignUp'
import { AllInvoicesScreen } from 'screens/Spending/Invoices/AllInvoicesScreen'
import { ApprovedInvoicesScreen } from 'screens/Spending/Invoices/ApprovedInvoicesScreen'
import { NeedsAttentionScreen } from 'screens/Spending/Invoices/NeedsAttentionScreen'
import { ProcessingInvoicesScreen } from 'screens/Spending/Invoices/ProcessingInvoicesScreen'
import { ViewPendingInvoice } from 'screens/Spending/Invoices/SingleInvoice/PendingInvoice'
import { ViewInvoiceHistory } from 'screens/Spending/Invoices/SingleInvoice/RegularInvoice'
import { SpendLayout } from 'screens/Spending/SpendLayout'
import { StockLayout as Stock } from 'screens/Stock'
import { AddItem } from 'screens/Stock/AddItem'
import { EditStocktake } from 'screens/Stock/Edit'
import { NewStocktake } from 'screens/Stock/New'
import { Stocktake } from 'screens/Stock/Stocktake'
import { SupplierEdit } from 'screens/Supplier/Edit'
import { Suppliers } from 'screens/Supplier/List'
import { SelectSuppliers } from 'screens/Supplier/SelectMy'
import { SupplierView } from 'screens/Supplier/View'
import { Todo } from 'screens/Todo'
import { VerifyEmail } from 'screens/VerifyEmail'
import { VerifyPhone } from 'screens/VerifyPhone'
import { ViewComponent } from 'screens/View'
import { Welcome } from 'screens/Welcome'

import { routes } from './Paths'
import { DashboardRedirect } from './Redirects/DashboardRedirect'
import { KitchenSelectorRedirect } from './Redirects/KitchenSelectorRedirect'
import { NotFoundRedirect } from './Redirects/NotFoundRedirect'
import { OnboardingRedirect } from './Redirects/OnboardingRedirect'
import { Redirect } from './Redirects/Redirect'
import { SignInRedirect } from './Redirects/SignInRedirect'

import { VITE_REACT_APP_DEMO_JOIN_CODE } from '../app'
import { IOSInstallBanner } from '../components/newUi/IOSInstallBanner'
import { MainLayout } from '../components/newUi/NewLayout'
import { useVersionChecker } from '../hooks/useVersionChecker'
import { AuthLayout } from '../layouts'
import { EditInvoice } from '../screens/Spending/Invoices/SingleInvoice/EditInvoice'

export function Routes() {
  const location = useLocation()
  const { checkVersion } = useVersionChecker()

  useEffect(() => void checkVersion(), [location.pathname])

  const joinDemoRedirect = (
    <Redirect path={`${routes.Join}/${VITE_REACT_APP_DEMO_JOIN_CODE}`} />
  )

  return (
    <RouterRoutes>
      {/* PRIVATE */}
      <Route
        element={
          <>
            <SignInRedirect />
            <OnboardingRedirect />
          </>
        }
      >
        <Route
          element={
            <>
              <KitchenSelectorRedirect />
              <MainLayout />
            </>
          }
        >
          <Route
            path={`${routes.Create}/:id/:type/*`}
            element={<CreateComponent />}
          />
          <Route
            path={`${routes.View}/:id/:type/*`}
            element={<ViewComponent />}
          />
          <Route
            path={`${routes.Product}${routes.Create}`}
            element={<CreateProduct />}
          />
          <Route
            path={`${routes.Product}${routes.Update}/:id`}
            element={<UpdateIngredient />}
          />
          <Route path={`${routes.Product}/:id`} element={<ProductLayout />}>
            <Route path={routes.Home} element={<PriceHistory />} />
            <Route
              path={`${routes.Price}${routes.History}`}
              element={<PriceHistory />}
            />
            <Route
              path={`${routes.Order}${routes.History}`}
              element={<ProductOrderHistory />}
            />
            <Route path={`${routes.Costing}`} element={<Costing />} />
          </Route>
          <Route
            path={`${routes.Product}${routes.List}`}
            element={<ListIngredients />}
          />
          <Route path={routes.Supplier}>
            <Route path={routes.Home} element={<Suppliers />} />
            <Route path={`${routes.Home}:id`} element={<ProductList />} />
            <Route
              path={`${routes.Select}${routes.Me}`}
              element={<SelectSuppliers />}
            />
            <Route
              path={`${routes.Select}${routes.All}`}
              element={<OrderSupplierAdd />}
            />
          </Route>

          <Route path={routes.Settings}>
            <Route path={routes.Home} element={<Settings />} />

            {/* Profile */}
            <Route path={routes.Me} element={<Me />} />
            <Route path={routes.Me + routes.Edit} element={<MeEdit />} />

            {/* Team */}
            <Route path={routes.Team} element={<Team />} />
            <Route path={`${routes.Team}${routes.Add}`} element={<AddTeam />} />
            <Route path={`${routes.Team}/:id`} element={<EditTeam />} />

            {/* Locations */}
            <Route path={routes.Locations} element={<Locations />} />

            {/* Integrations */}
            <Route path={routes.Integrations} element={<Integrations />} />
            <Route path={routes.Integrations + routes.EposNow}>
              <Route
                path={routes.Home}
                element={
                  <Redirect path={routes.Settings + routes.Integrations} />
                }
              />

              <Route path={routes.Intro} element={<EposNowIntro />} />
              <Route path={routes.Installed} element={<EposNowInstalled />} />
              <Route path={routes.Install} element={<EposNowInstall />} />
              <Route path={routes.Connect} element={<EposNowConnect />} />
              <Route path={routes.Filters} element={<EposNowCategories />} />
              <Route
                path={routes.Locations}
                element={<EposNowLocationSelect />}
              />
            </Route>

            <Route path={routes.Integrations + routes.Square}>
              <Route
                path={routes.Home}
                element={
                  <Redirect path={routes.Settings + routes.Integrations} />
                }
              />

              <Route path={routes.Intro} element={<SquareIntro />} />
              <Route path={routes.Install} element={<SquareInstall />} />
              <Route path={routes.Installed} element={<SquareInstalled />} />
              <Route path={routes.Callback} element={<SquareCallback />} />
              <Route path={routes.Filters} element={<SquareCategories />} />

              <Route
                path={routes.Locations}
                element={<SquareLocationSelect />}
              />
            </Route>

            {/* Addresses */}
            <Route path={routes.Addresses} element={<Addresses />} />
            <Route
              path={`${routes.Addresses + routes.Add}`}
              element={<AddAddress />}
            />
            <Route
              path={`${routes.Addresses + routes.Edit}/:id`}
              element={<EditAddress />}
            />

            {/* Plans */}
            <Route path={routes.Plans} element={<Plans />} />
            <Route path={`${routes.Plans}/:id`} element={<PlanDetails />} />

            {/* Refer */}
            <Route path={routes.Refer} element={<Refer />} />
          </Route>

          {/* Insights */}
          <Route path={routes.Spending} element={<InsightLayout />}>
            <Route path={routes.History} element={<SpendingHistory />} />
            <Route
              path={`${routes.History}/:from/:to`}
              element={<SpendingHistory />}
            />
            <Route path={routes.FoodFlash} element={<FoodFlash />} />
            <Route
              path={`${routes.FoodFlash}/:from/:to`}
              element={<FoodFlash />}
            />
            <Route path={routes.Changes} element={<PriceAlert />} />

            <Route path={routes.SalesMix} element={<SalesMix />} />
          </Route>

          {/* Spend */}
          <Route path={routes.Spending} element={<SpendLayout />}>
            <Route path={routes.Invoice}>
              <Route
                path={`${routes.History}`}
                element={<NeedsAttentionScreen />}
              />
              <Route
                path={`${routes.Approved}`}
                element={<ApprovedInvoicesScreen />}
              />
              <Route
                path={`${routes.Pending}`}
                element={<ProcessingInvoicesScreen />}
              />
              <Route path={`${routes.All}`} element={<AllInvoicesScreen />} />
            </Route>

            <Route
              path={routes.Home}
              element={
                <Redirect
                  path={`${routes.Spending}${routes.Invoice}${routes.History}`}
                />
              }
            />
          </Route>

          <Route path={routes.Spending}>
            <Route path={routes.Invoice}>
              <Route
                path={`${routes.History}/:id${routes.Edit}`}
                element={<EditInvoice />}
              />
              <Route
                path={`${routes.History}/:id`}
                element={<ViewInvoiceHistory />}
              />
              <Route
                path={`${routes.Pending}/:id`}
                element={<ViewPendingInvoice />}
              />
            </Route>
          </Route>

          <Route path={routes.Book} element={<BookLayout />}>
            <Route
              path={routes.Home}
              element={<Redirect path={`${routes.Book}${routes.List}`} />}
            />
            <Route path={routes.List} element={<NewBook />} />
            <Route path={`${routes.Menu}`} element={<Menus />} />
          </Route>
          <Route
            path={`${routes.Book}${routes.Create}`}
            element={<CreateBook />}
          />
          <Route
            path={`${routes.Book}${routes.Menu}${routes.Create}`}
            element={<CreateMenu />}
          />
          <Route
            path={`${routes.Book}${routes.Recipe}${routes.Create}`}
            element={<CreateRecipe />}
          />
          <Route
            path={`${routes.Book}${routes.Dish}${routes.Create}`}
            element={<CreateDish />}
          />
          <Route
            path={`${routes.Invoice}${routes.History}`}
            element={<NeedsAttentionScreen />}
          />
          <Route
            path={`${routes.Invoice}${routes.Approved}`}
            element={<ApprovedInvoicesScreen />}
          />
          <Route
            path={`${routes.Book}${routes.Menu}${routes.View}/:id`}
            element={<ViewMenu />}
          />
          <Route
            path={`${routes.Book}${routes.Menu}${routes.Edit}/:id`}
            element={<EditMenu />}
          />
          <Route path={routes.Order} element={<Ordering />}>
            <Route path={routes.History} element={<OrderHistory />} />
            <Route
              path={routes.Home}
              element={<Redirect path={`${routes.Order}${routes.History}`} />}
            />
          </Route>
          <Route
            path={`${routes.Book}${routes.Menu}${routes.Allergen}/:id`}
            element={<Allergens />}
          />
          <Route
            path={`${routes.Order}${routes.Supplier}${routes.Add}`}
            element={<OrderSupplierAdd />}
          />
          <Route
            path={`${routes.Order}${routes.Supplier}`}
            element={<OrderSupplier />}
          />
          <Route
            path={`${routes.Order}${routes.Supplier}${routes.View}/:id`}
            element={<SupplierView />}
          />
          <Route
            path={`${routes.Order}${routes.Supplier}${routes.Edit}/:id`}
            element={<SupplierEdit />}
          />
          <Route
            path={`${routes.Order}${routes.Checkout}/:id`}
            element={<Checkout />}
          />
          <Route
            path={`${routes.Order}${routes.Supplier}${routes.Add}`}
            element={<OrderSupplierAdd />}
          />
          <Route
            path={`${routes.Order}${routes.Supplier}/:id`}
            element={<OrderSupplierProducts />}
          />
          <Route
            path={`${routes.Order}${routes.Supplier}/:id${routes.Basket}`}
            element={<OrderSupplierBasket />}
          />
          <Route
            path={`${routes.Spending}${routes.Invoice}${routes.Add}`}
            element={<Add />}
          />
          <Route
            path={`${routes.Order}${routes.History}/:id`}
            element={<OrderHistoryItem />}
          />
          <Route path={routes.VerifyEmail} element={<VerifyEmail />} />

          <Route
            path="/invoices/add"
            element={
              <Redirect
                path={`${routes.Spending}${routes.Invoice}${routes.Add}`}
              />
            }
          />
          <Route
            path="/pricechanges"
            element={<Redirect path={`${routes.Spending}${routes.Changes}`} />}
          />
          <Route
            path="/spending-history"
            element={<Redirect path={`${routes.Spending}${routes.History}`} />}
          />
          <Route path={routes.Stock} element={<Stock />}>
            <Route path={routes.Take} element={<Stocktake />} />
            <Route path={routes.Product} element={<Products />} />
            <Route
              path={routes.Home}
              element={<Redirect path={`${routes.Stock}${routes.Take}`} />}
            />
          </Route>
          <Route
            path={`${routes.Stock}${routes.Take}${routes.Create}/:cursor`}
            element={<NewStocktake />}
          />
          <Route
            path={`${routes.Stock}${routes.Take}${routes.Create}`}
            element={<NewStocktake />}
          />
          <Route
            path={`${routes.Stock}/:id${routes.Add}`}
            element={<AddItem />}
          />
          <Route path={`${routes.Stock}/:id`} element={<EditStocktake />} />

          <Route path={`${routes.Todo}/:id`} element={<Todo />} />

          <Route path={routes.Home} element={<Home />} />
          <Route path={routes.Finance} element={<Finance />} />
          <Route path={routes.Kitchen} element={<Kitchen />} />

          <Route path={routes.Kitchen}>
            <Route path={routes.Import} element={<ImportLayout />}>
              <Route path={routes.Linked} element={<Linked />} />
              <Route path={routes.Unlinked} element={<Unlinked />} />

              <Route
                path="/"
                element={
                  <Redirect
                    path={routes.Kitchen + routes.Import + routes.Unlinked}
                  />
                }
              />
            </Route>
          </Route>
        </Route>

        {/* Onboarding Routes */}
        <Route element={<AuthLayout />}>
          <Route path={routes.SetPassword} element={<SetPassword />} />
          <Route path={routes.SetJobRoles} element={<SetJobRoles />} />
          <Route
            path={routes.SetJobResponsibilities}
            element={<SetJobResponsibilities />}
          />
          <Route
            path={routes.SetProfilePicture}
            element={<SetProfilePicture />}
          />
        </Route>
      </Route>

      {/* PUBLIC */}
      <Route
        element={
          <>
            <DashboardRedirect />
            <AuthLayout />
            <IOSInstallBanner />
          </>
        }
      >
        <Route path={routes.Home} element={joinDemoRedirect} />
        <Route path={routes.Welcome} element={<Welcome />} />
        <Route path={routes.SignIn} element={<SignIn />} />
        <Route path={routes.SignUp} element={<SignUp />} />
        <Route path={routes.ForgotPassword} element={<ForgotPassword />} />
        <Route path={routes.ResetPassword} element={<ResetPassword />} />
        <Route path={routes.NewPassword} element={<NewPassword />} />
        <Route path={routes.VerifyPhone} element={<VerifyPhone />} />

        <Route path={routes.VerifyEmail} element={<VerifyEmail />} />

        {/* Old Routes */}
        <Route path={routes.SignUpPhone} element={joinDemoRedirect} />
        <Route path={routes.VerifySignUpPhone} element={joinDemoRedirect} />
        <Route path={routes.CompleteSignUpPhone} element={joinDemoRedirect} />
        <Route path={routes.Join + routes.Verify} element={joinDemoRedirect} />

        <Route
          path={routes.Join + routes.CreateProfile}
          element={joinDemoRedirect}
        />
      </Route>

      {/* BOTH */}
      <Route path={routes.Home} element={<AuthLayout />}>
        <Route path={`${routes.Join}/:code`} element={<AcceptInvite />} />
      </Route>

      <Route path="*" element={<NotFoundRedirect />} />
    </RouterRoutes>
  )
}
