import {
  action,
  get,
  makeObservable,
  observable,
  ObservableMap,
  set,
} from 'mobx'
import { createContext, FunctionComponent, useContext, ReactNode } from 'react'

import { FormState } from 'mobx/StateStore/StateStore'

export class FormStateStore {
  constructor() {
    makeObservable(this, {
      createOrGet: action,
      getFormState: action,
      states: observable,
    })
    this.states = new ObservableMap<string, FormState>()
  }

  createOrGet = (key: string) => {
    if (!this.states.has(key)) {
      set(this.states, key, new FormState())
    }

    return get(this.states, key) as FormState
  }

  resetStates = () => {
    this.states = new ObservableMap<string, FormState>()
  }

  getFormState = (key: string) => {
    return get(this.states, key)
  }

  states: ObservableMap<string, FormState>
}

export const FormStoreContext = createContext<FormStateStore>(
  {} as FormStateStore,
)

export interface IProps {
  children?: ReactNode
}

export const NewFormStoreProvider: FunctionComponent<IProps> = (props) => (
  <FormStoreContext.Provider value={new FormStateStore()}>
    {props.children}
  </FormStoreContext.Provider>
)

export const useNewStateStore = () => {
  const storeContext = useContext(FormStoreContext)

  return storeContext
}
