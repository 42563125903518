import { useMutation, useQuery } from '@apollo/client'
import {
  AcceptInvitePanel,
  JellyLogoPrimary,
  Typography,
} from '@getjelly/jelly-ui'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { inviteDetailsQuery, acceptInviteMutation } from './query'

import {
  Mutation,
  MutationAcceptInviteArgs,
  Query,
  QueryKitchenInvitePublicArgs,
} from '../../api'
import { Loader } from '../../components'
import { useMe } from '../../hooks/auth'
import { routes } from '../../routes/Paths'
import { selectToken } from '../../store/auth'
import { setInvite } from '../../store/authFlow'
import { cleanErrorMessage } from '../../utils'

export function AcceptInvite() {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()

  const [error, setError] = useState('')

  const search = useMemo(() => new URLSearchParams(location.search), [location])

  const invite = useMemo(() => {
    return {
      inviteCode: search.get('i') ?? undefined,
      joinCode: params.code,
    }
  }, [params, search])

  const { refetch } = useMe()

  const { data: inviteData, loading } = useQuery<
    { kitchenInvitePublic: Query['kitchenInvitePublic'] },
    QueryKitchenInvitePublicArgs
  >(inviteDetailsQuery, {
    skip: !invite.joinCode,
    variables: invite,
  })

  const [acceptInvite] = useMutation<
    { acceptInvite: Mutation['acceptInvite'] },
    MutationAcceptInviteArgs
  >(acceptInviteMutation)

  async function joinKitchen() {
    if (!inviteData?.kitchenInvitePublic) {
      return setError('This join code is invalid or has expired.')
    }

    try {
      const { data } = await acceptInvite({ variables: invite })

      if (!data?.acceptInvite) {
        throw new Error('Failed to accept invite, unexpected error occurred.')
      }
    } catch (e) {
      return setError(cleanErrorMessage((e as Error).message) || '')
    }

    navigate(routes.Settings + routes.Locations)
  }

  async function submit() {
    const user = await refetch()
    if (user) {
      return await joinKitchen()
    }

    dispatch(setInvite(invite))

    return navigate(
      inviteData?.kitchenInvitePublic?.isExistingUser
        ? routes.SignIn
        : routes.Welcome,
    )
  }

  if (loading) {
    return <Loader />
  }

  if (!inviteData?.kitchenInvitePublic?.kitchenName || error) {
    return (
      <div className="shadow w-full rounded-md">
        <div className="rounded-t-md bg-white p-4 flex flex-col items-center justify-center">
          <JellyLogoPrimary />
        </div>

        <div className="flex flex-col items-center space-y-8 rounded-b-md bg-primary-50 px-4 py-8 text-center">
          <div className="flex flex-col space-y-6 w-full">
            <div className="flex flex-col space-y-2">
              <Typography style="h6">Invalid Invitation</Typography>

              <Typography style="caption" className="text-primary-600">
                {error ||
                  'This invite link is not valid. Please ask your team for another invite link.'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <AcceptInvitePanel
      acceptInvite={submit}
      kitchen={{ name: inviteData.kitchenInvitePublic.kitchenName }}
    />
  )
}
