import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import { DocumentType } from 'api'
import { RootState } from 'store'

type State = {
  documentType: DocumentType | undefined
}

const initialState: State = {
  documentType: undefined,
}

const documentTypeSlice = createSlice({
  initialState,
  name: 'documentType',
  reducers: {
    setDocumentType(state, action: PayloadAction<DocumentType | undefined>) {
      state.documentType = action.payload
    },
  },
})

export const { setDocumentType } = documentTypeSlice.actions
export const reducer = documentTypeSlice.reducer

const selectState = (state: RootState) => state.documentType

export const selectDocumentType = () =>
  createSelector(selectState, (state) => state.documentType)
