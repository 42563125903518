import { InsightsListGroup, InsightsListItem } from '@getjelly/jelly-ui'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { SpendTotal, Supplier } from '../../../api'
import { routes } from '../../../routes/Paths'
import { setSupplier } from '../../../store/supplier'

type Props = {
  spendTotals: SpendTotal[]
}

export function SpendList({ spendTotals }: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <>
      <InsightsListGroup title="By Supplier">
        {spendTotals.map((s) => (
          <InsightsListItem
            key={s.supplierId}
            title={s.supplierName}
            subtitle={s.invoiceCount + ' documents'}
            data={s.total.toLocaleString(undefined, {
              currency: 'GBP',
              style: 'currency',
            })}
            accent="secondary"
            onClick={() => {
              dispatch(
                setSupplier({
                  id: s.supplierId,
                  name: s.supplierName,
                } as unknown as Supplier),
              )

              navigate(
                `${routes.Spending}/${routes.Invoice}/${routes.Approved}`,
              )
            }}
          />
        ))}
      </InsightsListGroup>
    </>
  )
}
