import { useEffect, useState } from 'react'

import { UpdateSalesAutomatedModal } from './UpdateSalesAutomatedModal'
import { UpdateSalesManualModal } from './UpdateSalesManualModal'

export type CurrentSales = {
  date: Date
  sales: number | undefined | null
  automated: number | undefined | null
}

type Props = {
  currentSales: CurrentSales
  open: boolean
  onClose: () => void
  saveSales: (date: Date, sales: number | null) => Promise<void>
}

export function UpdateSalesModal({
  open,
  onClose,
  currentSales,
  saveSales,
}: Props) {
  const [automated, setAutomated] = useState(currentSales.automated || null)

  useEffect(() => setAutomated(currentSales.automated || null), [currentSales])

  if (automated === null) {
    return (
      <UpdateSalesManualModal
        currentSales={currentSales}
        open={open}
        onClose={onClose}
        saveSales={saveSales}
      />
    )
  }

  return (
    <UpdateSalesAutomatedModal
      currentSales={currentSales}
      open={open}
      onClose={onClose}
      saveSales={saveSales}
      automated={automated}
    />
  )
}
