import { IconChevronRight } from '@tabler/icons-react'
import { useMemo } from 'react'

export function UnapprovedInvoiceSkeleton() {
  const showFirstLabel = true
  const showSecondLabel = useMemo(() => Math.random() > 0.5, [])
  const showThirdLabel = useMemo(() => Math.random() > 0.5, [])

  return (
    <div className="h-[113px] w-full px-2 border-b border-primary-50 flex justify-between items-center">
      <div className="space-y-2">
        <div className="space-y-0.5">
          <div className="rounded h-4 w-32 bg-primary-200" />
          <div className="rounded h-3 w-24 bg-primary-100" />
          <div className="rounded h-3 w-32 bg-primary-50" />
        </div>

        <div className="flex space-x-1">
          {showFirstLabel && (
            <div className="rounded-full h-[20px] w-16 bg-primary-100" />
          )}

          {showSecondLabel && (
            <div className="rounded-full h-[20px] w-16 bg-primary-50" />
          )}

          {showThirdLabel && (
            <div className="rounded-full h-[20px] w-16 bg-primary-100" />
          )}
        </div>
      </div>

      <div className="space-y-1 flex space-x-1">
        <div className="space-y-1 flex flex-col items-end">
          <div className="rounded-full bg-primary-100 h-5 w-24" />
          <div className="rounded-full bg-primary-50 h-4 w-16" />
        </div>

        <div className="flex items-center">
          <IconChevronRight className="text-primary-100" />
        </div>
      </div>
    </div>
  )
}
