import { useQuery } from '@apollo/client'
import {
  FastestCostingOnPlanetEmptyState,
  TextInput,
  Typography,
} from '@getjelly/jelly-ui'
import { IconSearch } from '@tabler/icons-react'
import { isNil } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'

import { Query, QueryMode, QueryRecipeListArgs, SortOrder, Status } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Radio } from 'components/newUi'
import { useDebounce } from 'hooks'
import { recipeList } from 'screens/Book/graphql'

import { Item } from './Item'

import { Loader } from '../../../components'
import { routes } from '../../../routes/Paths'
import { selectCounts } from '../../../store/kitchen'

export function NewBook() {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()
  const [filter, setFilter] = useState('')
  const [scrollIndex, setScrollIndex] = useState<number>()
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('query') ?? ''

  const debouncedSearch = useDebounce(search, 500)

  const {
    data: queryResult,
    loading,
    fetchMore,
  } = useQuery<{ recipeList: Query['recipeList'] }, QueryRecipeListArgs>(
    recipeList,
    {
      notifyOnNetworkStatusChange: true,
      skip: !selectedKitchen?.id,
      variables: {
        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        where: {
          NOT: [
            {
              status: { equals: Status.Inactive },
            },
          ],
          dishes:
            filter === 'dish'
              ? { some: { id: { gte: 0 } } }
              : filter === 'recipe'
              ? { none: {} }
              : undefined,
          kitchenId: {
            equals: selectedKitchen?.id,
          },
          name: {
            contains: debouncedSearch,
            mode: QueryMode.Insensitive,
          },
        },
      },
    },
  )

  const data = useMemo(
    () => (!queryResult ? [] : queryResult.recipeList.nodes),
    [queryResult],
  )

  useEffect(() => {
    setScrollIndex(0)
  }, [debouncedSearch])

  useEffect(() => {
    if (isNil(scrollIndex)) return
    if (scrollIndex === 0) {
      setScrollIndex(undefined)
    }
  }, [scrollIndex])

  const counts = useSelector(selectCounts())

  if (!counts.dishCount && !counts.recipeCount && !data.length) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <FastestCostingOnPlanetEmptyState
            ctaClicked={() => navigate(routes.Book + routes.Create)}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="p-3 space-y-3 bg-primary-50">
        <TextInput
          placeholder="Search by name"
          value={search}
          onChange={(value) =>
            setSearchParams({ query: value as string }, { replace: true })
          }
          icon={IconSearch}
        />

        <div className="flex items-center space-x-2">
          <Typography style="caption" className="text-primary-600">
            Show:
          </Typography>

          <div className="flex space-x-4">
            <Radio
              active={filter === ''}
              label="All"
              onClick={() => {
                if (filter !== '') {
                  setFilter('')
                  setScrollIndex(0)
                }
              }}
            />

            <Radio
              active={filter === 'dish'}
              label="Dish"
              onClick={() => {
                if (filter !== 'dish') {
                  setFilter('dish')
                  setScrollIndex(0)
                }
              }}
            />

            <Radio
              active={filter === 'recipe'}
              label="Recipe"
              onClick={() => {
                if (filter !== 'recipe') {
                  setFilter('recipe')
                  setScrollIndex(0)
                }
              }}
            />
          </div>
        </div>
      </div>

      {loading && !queryResult?.recipeList && <Loader />}

      <div className="flex flex-1 flex-col">
        <AutoSizer>
          {({ height, width }) => {
            const rowCount = data?.length ?? 0
            return (
              <List
                containerStyle={{ overflow: 'hidden' }}
                noRowsRenderer={() => (
                  <div className="flex justify-center items-center p-1 w-full">
                    <Typography style="subtitle2" className="text-primary-900">
                      Could not find any items
                    </Typography>
                  </div>
                )}
                scrollToIndex={scrollIndex}
                width={width}
                height={height}
                rowCount={rowCount}
                rowHeight={65}
                overscanRowCount={5}
                onRowsRendered={({ overscanStopIndex }) => {
                  if (
                    overscanStopIndex === rowCount - 1 &&
                    !loading &&
                    queryResult?.recipeList.pageInfo?.hasNextPage &&
                    fetchMore
                  ) {
                    fetchMore({
                      variables: {
                        cursor: queryResult.recipeList.pageInfo.endCursor,
                      },
                    })

                    return
                  }
                }}
                rowRenderer={(props) => <Item {...props} data={data} />}
              />
            )
          }}
        </AutoSizer>
      </div>
    </>
  )
}
