import {
  Button,
  DateInput,
  Modal,
  TextInput,
  Typography,
} from '@getjelly/jelly-ui'
import { differenceInDays, subMonths } from 'date-fns'
import dayjs from 'dayjs'
import { useState } from 'react'

import { Kitchen } from 'api'

import { errorToast, successToast } from '../../../../components/toasts'
import {
  triggerInvoiceDownload,
  csvDownload,
} from '../rest/triggerInvoiceDownload'

interface ExportInvoiceData {
  invoiceDate: string
  invoiceId: string
  invoiceTotal: number
  supplierName: string
  vat: number
}

const buildCSV = (invoices: ExportInvoiceData[]) => {
  const csvRows = [
    [
      'AccountCode',
      'ContactName',
      'InvoiceNumber',
      'InvoiceDate',
      'DueDate',
      'Total',
      'Quantity',
      'UnitAmount',
      'LineAmount',
      'TaxType',
      'TaxAmount',
      'Description',
    ],
  ].concat(
    invoices.map((invoice) => {
      const invoiceDate = invoice!.invoiceDate!
        ? dayjs(invoice!.invoiceDate!)
        : ''
      const unitAmount = (
        (invoice!.invoiceTotal ?? 0) - (invoice!.vat ?? 0)
      ).toString()

      return [
        '310',
        invoice!.supplierName,
        invoice!.invoiceId!,
        invoiceDate ? invoiceDate.toISOString() : '',
        invoiceDate ? invoiceDate.add(30, 'day').toISOString() : '',
        (invoice!.invoiceTotal ?? 0).toString(),
        '1',
        unitAmount,
        unitAmount,
        invoice!.vat ? '20% (VAT on Expenses)' : 'Zero Rated Expenses',
        (invoice!.vat ?? 0).toString(),
        '',
      ]
    }),
  )

  return csvRows.map((e) => e.join(',')).join('\n')
}

export const ExportInvoicesModal = ({
  show,
  selectedKitchen,
  setDisplay,
}: {
  show: boolean
  selectedKitchen: Kitchen
  setDisplay: (arg0: boolean) => void
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    subMonths(new Date(), 1),
  )
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [emailConfirmation, setEmailConfirmation] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  )

  const exportCSV = () => {
    if (!startDate) {
      errorToast('Start date is required.')
      return
    }

    if (!endDate) {
      setErrorMessage('End date is required.')
      return
    }

    csvDownload(
      startDate,
      endDate,
      selectedKitchen?.id,
      function (data: any) {
        const csvContent = buildCSV(data.invoices)
        const blob = new Blob([csvContent], { type: 'text/csv' })
        window.open(window.URL.createObjectURL(blob))

        setDisplay(false)
        setIsSubmitting(false)
      },
      function (errorMsg: string) {
        setIsSubmitting(false)
        errorToast(errorMsg)
        return
      },
    )
  }

  const mailInvoices = () => {
    if (!startDate) {
      errorToast('Start date is required.')
      return
    }

    if (!endDate) {
      errorToast('End date is required.')
      return
    }

    if (!emailAddress) {
      errorToast('Please enter your email address.')
      return
    }

    triggerInvoiceDownload(
      startDate,
      endDate,
      emailAddress,
      selectedKitchen?.id,
      function () {
        setEmailConfirmation(false)
        setDisplay(false)
        successToast('You will receive an email shortly with the raw images.')
      },
      function (errorMsg: string) {
        setIsSubmitting(false)
        errorToast(errorMsg)
        return
      },
    )
  }

  return (
    <Modal
      open={show}
      onClose={() => {
        setDisplay(false)
        setIsSubmitting(false)
        setEmailConfirmation(false)
      }}
    >
      <div className="space-y-2">
        <Typography style="h6" className="text-primary-900">
          Export Documents
        </Typography>

        {emailConfirmation && (
          <Typography style="body2">
            Raw images will be sent to this email address.
          </Typography>
        )}

        {!errorMessage && !emailConfirmation && (
          <Typography style="body2">
            Please select the start and end dates for the documents you wish to
            export.
          </Typography>
        )}

        {errorMessage && (
          <Typography style="body2" className="text-red-500">
            {errorMessage}
          </Typography>
        )}

        {emailConfirmation && (
          <div className="space-y-4">
            <TextInput
              placeholder="Email address"
              value={emailAddress}
              onChange={setEmailAddress}
            />

            <Button
              label="Send"
              loading={isSubmitting}
              onClick={() => {
                mailInvoices()
              }}
              className="w-full"
            />
          </div>
        )}

        {!emailConfirmation && (
          <div className="space-y-4">
            <div className="flex space-x-2 py-2">
              <DateInput
                value={startDate}
                onChange={(date) => {
                  setStartDate(date)
                  setErrorMessage(undefined)
                }}
                placeholder="Pick start date"
              />

              <DateInput
                value={endDate}
                onChange={(date) => {
                  setEndDate(date)
                  setErrorMessage(undefined)
                }}
                placeholder="Pick end date"
              />
            </div>

            <div className="space-y-2">
              <Button
                label="Export CSV for XERO"
                loading={isSubmitting}
                onClick={() => {
                  setIsSubmitting(true)
                  exportCSV()
                }}
                className="w-full"
              />

              <Button
                label="Export raw images"
                loading={isSubmitting}
                onClick={() => {
                  if (!startDate) {
                    errorToast('Start date is required.')
                    return
                  }

                  if (!endDate) {
                    errorToast('End date is required.')
                    return
                  }

                  if (differenceInDays(startDate, endDate) > 45) {
                    errorToast('Date range needs to be less than 45 days.')
                    return
                  }

                  setEmailConfirmation(true)
                }}
                className="w-full"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
