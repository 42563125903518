import { useQuery } from '@apollo/client'
import {
  InfoAlert,
  InvoicesOnePlaceEmptyState,
  InvoicesOnePlaceNotPaidState,
  Typography,
} from '@getjelly/jelly-ui'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AutoSizer, List } from 'react-virtualized'

import {
  PendingInvoiceGroupedConnection,
  QueryProcessingInvoiceListArgs,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { useIsPaidKitchen } from 'hooks'
import PendingInvoiceRowItem from 'screens/Spending/Invoices/components/RowItems/PendingInvoiceRowItem'
import { processingInvoiceList } from 'screens/Spending/Invoices/graphql/processingInvoiceList'

import { Loader } from '../../../components'
import { GroupedInvoicesSkeleton } from '../../../components/Skeleton/Invoice/GroupedInvoicesSkeleton'
import { PendingInvoiceSkeleton } from '../../../components/Skeleton/Invoice/PendingInvoiceSkeleton'
import { useThrottleDebounce } from '../../../hooks/useThrottleDebounce'
import { selectCounts } from '../../../store/kitchen'

export function ProcessingInvoicesScreen() {
  const { selectedKitchen } = useKitchen()
  const isFeatureAvailable = useIsPaidKitchen()

  const counts = useSelector(selectCounts())

  const { data, loading, fetchMore, client } = useQuery<
    { processingInvoiceList: PendingInvoiceGroupedConnection },
    QueryProcessingInvoiceListArgs
  >(processingInvoiceList, {
    fetchPolicy: 'network-only',
    skip: !selectedKitchen,
    variables: {
      kitchenCursor: selectedKitchen?._cursor ?? '',
      skip: 0,
      take: 20,
    },
  })

  useEffect(() => {
    client.cache.evict({ fieldName: 'processingInvoiceList' })
  }, [])

  const handleScroll = useThrottleDebounce(
    ({
      scrollTop,
      scrollHeight,
      clientHeight,
    }: {
      scrollTop: number
      scrollHeight: number
      clientHeight: number
    }) => {
      if (loading) {
        return
      }

      // Fetch more if user is in bottom 90% of scroll.
      if (scrollTop + clientHeight >= scrollHeight * 0.9) {
        fetchMore({
          variables: {
            skip: data?.processingInvoiceList.nodes.length,
            take: 20,
          },
        })
      }
    },
    500,
  )

  if (!isFeatureAvailable) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <InvoicesOnePlaceNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (
    counts.invoiceCount === 0 &&
    (data?.processingInvoiceList.count ?? 0) === 0
  ) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <InvoicesOnePlaceEmptyState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-1 flex-col">
      <InfoAlert text="Document processing takes up to 24 hrs. PDF files are processed faster than photos." />

      {!data && (
        <GroupedInvoicesSkeleton
          showHeaders={false}
          invoiceSkeleton={<PendingInvoiceSkeleton />}
        />
      )}

      {data && (
        <AutoSizer>
          {({ height, width }) => (
            <List
              rowCount={data.processingInvoiceList.nodes.length ?? 0}
              height={height - 72}
              rowHeight={95}
              rowRenderer={({ index, key, style }) => (
                <PendingInvoiceRowItem
                  key={key}
                  style={style}
                  invoice={data?.processingInvoiceList.nodes[index]}
                />
              )}
              width={width}
              overscanRowCount={10}
              onScroll={handleScroll}
              noRowsRenderer={() =>
                loading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center justify-center w-full py-6 px-4">
                    <Typography style="subtitle2" className="text-primary-600">
                      Could not find any items
                    </Typography>
                  </div>
                )
              }
            />
          )}
        </AutoSizer>
      )}
    </div>
  )
}
