import { Dish, Recipe } from 'api'
import NoImage from 'assets/recipe-no-image.jpg'
import { ScrollMenu, ScrollMenuItem, Typography } from 'components/newUi'
import { useWindowSize } from 'hooks'
import { ImageContainer } from 'screens/Create/ImageContainer'
import { Allergens } from 'screens/View/Details/Allergens'
import { Costing } from 'screens/View/Details/Costing'
import { Ingredients } from 'screens/View/Details/Ingredients'
import { Method } from 'screens/View/Details/Method'
import { theme } from 'styles/newUi'
import { capitaliseEachWord } from 'utils'

import { useStyles } from './styles'

export interface Props {
  setCurrentPage: (num: number) => void
  recipe: Recipe
  dish?: Dish
  id: string
  currentTab: number
  setCurrentTab: (tab: number) => void
}

export const Details = ({ recipe, dish, currentTab, setCurrentTab }: Props) => {
  const classes = useStyles()
  const totalCost = recipe.unitCost

  const costPerPortion: number = Number(totalCost) / (dish?.portion ?? 1)

  const { gte: isDesktop } = useWindowSize('md')

  return (
    <div className={classes.container}>
      <div>
        <div>
          <div className={classes.photoBox}>
            <ImageContainer
              style={{
                backgroundColor: recipe.imageUrl
                  ? 'none'
                  : theme.palette.primary[10].toHexString(),
                minHeight: isDesktop ? 180 : undefined,
                minWidth: isDesktop ? 380 : undefined,
                objectFit: recipe.imageUrl ? 'cover' : 'contain',
              }}
              mode="view"
              loading={false}
              imageSource={recipe.imageUrl ?? NoImage}
              altText={'Click to add a photo'}
              setImageUrl={() => {
                return
              }}
            />
          </div>

          <div
            className={classes.title}
            style={{
              padding: 12,
            }}
          >
            <div>
              <Typography variant="caption" testId="subtitle">
                {dish ? dish?.type : recipe?.section}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" testId="title">
                {capitaliseEachWord(recipe.name)}
              </Typography>
            </div>
          </div>

          <div className={classes.divider} />

          <ScrollMenu>
            {dish && (
              <ScrollMenuItem
                text="Pricing"
                selected={currentTab === 0}
                route={() => setCurrentTab(0)}
              />
            )}

            <ScrollMenuItem
              text="Ingredients"
              selected={currentTab === 1}
              route={() => setCurrentTab(1)}
            />

            <ScrollMenuItem
              text="Method"
              selected={currentTab === 2}
              route={() => setCurrentTab(2)}
            />

            <ScrollMenuItem
              text="Allergens"
              selected={currentTab === 3}
              route={() => setCurrentTab(3)}
            />
          </ScrollMenu>
        </div>

        {currentTab === 0 && dish ? (
          <Costing dish={dish} costPerPortion={costPerPortion} />
        ) : null}

        {currentTab === 1 ? <Ingredients recipe={recipe} /> : null}

        {currentTab === 2 ? (
          <Method
            recipe={recipe}
            id={dish ? dish.id : recipe.id}
            isDish={!!dish}
          />
        ) : null}

        {currentTab === 3 ? (
          <Allergens
            recipe={recipe}
            id={dish ? dish.id : recipe.id}
            isDish={!!dish}
          />
        ) : null}
      </div>
    </div>
  )
}

export const ViewMenuItem = ({
  tab,
  name,
  currentTab,
  setCurrentTab,
}: {
  tab: number
  name: string
  currentTab: number
  setCurrentTab: (tab: number) => void
}) => {
  return (
    // eslint-disable-next-line
    <div
      onClick={() => setCurrentTab(tab)}
      style={{
        backgroundColor: 'transparent',
        color: currentTab === tab ? undefined : 'rgba(121, 131, 146, 1)',
        cursor: 'pointer',
        paddingBottom: 15,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        position: 'relative',
        textAlign: 'center',
      }}
    >
      {name}
      <div
        style={{
          backgroundColor:
            currentTab === tab
              ? theme.palette.tertiary[100].toHexString()
              : 'rgba(219, 222, 226, 1)',
          bottom: -3,
          height: 3,
          position: 'absolute',
          right: 0,
          width: '100%',
        }}
      ></div>
    </div>
  )
}
