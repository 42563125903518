import { ActionModal, Typography } from '@getjelly/jelly-ui'
import { IconReceipt, IconSelector } from '@tabler/icons-react'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DocumentType } from 'api'

import {
  selectDocumentType,
  setDocumentType,
} from '../../../../store/documentType'
import { documentTypeDisplay } from '../../../../utils/text/documentTypeDisplay'

export function DocumentTypeSelect() {
  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(false)

  const documentType = useSelector(selectDocumentType())

  const filterText = useMemo(() => {
    if (!documentType) return 'All Documents'
    return documentTypeDisplay(documentType)
  }, [documentType])

  return (
    <>
      <ActionModal
        actions={[
          {
            icon: IconReceipt,
            onClick: () => {
              dispatch(setDocumentType())
              setOpenModal(false)
            },
            title: 'All documents',
          },
          {
            icon: IconReceipt,
            onClick: () => {
              dispatch(setDocumentType(DocumentType.Invoice))
              setOpenModal(false)
            },
            subtitle: 'A legal bill of purchase',
            title: 'Invoice',
          },
          {
            icon: IconReceipt,
            onClick: () => {
              dispatch(setDocumentType(DocumentType.CreditNote))
              setOpenModal(false)
            },
            subtitle: 'A refund on an invoice',
            title: 'Credit-note',
          },
          {
            icon: IconReceipt,
            onClick: () => {
              dispatch(setDocumentType(DocumentType.PurchaseOrder))
              setOpenModal(false)
            },
            subtitle: 'An order confirmation',
            title: 'Purchase-order',
          },
          {
            icon: IconReceipt,
            onClick: () => {
              dispatch(setDocumentType(DocumentType.DeliveryNote))
              setOpenModal(false)
            },
            subtitle: 'A note of items delivered',
            title: 'Delivery-note',
          },
          {
            icon: IconReceipt,
            onClick: () => {
              dispatch(setDocumentType(DocumentType.Statement))
              setOpenModal(false)
            },
            subtitle: 'A summary of invoices owed',
            title: 'Statement',
          },
        ]}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />

      <div
        className="w-full py-2 flex items-center justify-center space-x-1 cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        <Typography style="button" className="text-secondary-400">
          {filterText}
        </Typography>

        <IconSelector className="text-secondary-400" />
      </div>
    </>
  )
}
