import { combineReducers } from '@reduxjs/toolkit'

import { reducer as marketing } from 'components/newUi/Marketing/store'
import { reducer as pendingOrders } from 'screens/Order/store'

import { reducer as auth } from './auth'
import { reducer as authFlow } from './authFlow'
import { reducer as currentVersion } from './currentVersion'
import { reducer as documentType } from './documentType'
import { reducer as invoices } from './invoices'
import { reducer as kitchen } from './kitchen'
import { reducer as product } from './product'
import { reducer as stocktake } from './stocktake'
import { reducer as supplier } from './supplier'
import { tempReducer } from './temp'
import { reducer as toastQueue } from './toastQueue'
import { reducer as user } from './user'

export const rootReducer = combineReducers({
  auth,
  authFlow,
  currentVersion,
  documentType,
  invoices,
  kitchen,
  marketing,
  pendingOrders,
  product,
  stocktake,
  supplier,
  temp: tempReducer,
  toastQueue,
  user,
})
