export const shareInvoiceCodeHandler: (
  invoiceCode: string,
) => Promise<void> = async (invoiceCode: string) => {
  const shareMessage = `Hi there, we are using a new kitchen management system, can you make sure to include this email (${invoiceCode}) to receive all my invoices, credit-notes and other documents from you now.`

  if (navigator.share) {
    try {
      await navigator.share({
        text: shareMessage,
        title: 'Share Invoice Code',
        url: window.location.href,
      })
    } catch (error) {
      // TODO: handle error
    }
  } else {
    // Fallback to opening an email client
    const subject = encodeURIComponent(
      'Send my invoices to this email address too',
    )
    const body = encodeURIComponent(shareMessage)
    const mailtoUrl = `mailto:?subject=${subject}&body=${body}`

    // Opening the mail client in a new tab/window
    window.open(mailtoUrl, '_blank')
  }
}
