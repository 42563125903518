import { gql } from '@apollo/client'

export const allInvoiceList = gql`
  query allInvoiceList(
    $kitchenCursor: ID!
    $supplierId: Int!
    $skip: Int
    $take: Int
    $searchTerm: String
    $documentType: DocumentType
  ) {
    allInvoiceList(
      kitchenCursor: $kitchenCursor
      supplierId: $supplierId
      skip: $skip
      take: $take
      searchTerm: $searchTerm
      documentType: $documentType
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        skip
        take
        currentPage
        totalCount
        totalPages
      }
      count
      pendingCount
      nodes {
        createdAt
        createdBy {
          _cursor
          id
          firstName
          lastName
        }
        _cursor
        id
        isAuto
        notes {
          id
          content
          createdAt
          user {
            id
            firstName
            lastName
          }
        }
        updatedAt
        updatedBy {
          _cursor
          id
          firstName
          lastName
        }
        status
        rejectionReason
        rejectionReasonNotes
        images
        invoice {
          _cursor
          id
          approved
          isAuto
          documentType
          supplier {
            _cursor
            id
            name
          }
          createdBy {
            _cursor
            id
            firstName
            lastName
          }
          updatedBy {
            _cursor
            id
            firstName
            lastName
          }
          invoiceDate
          invoiceId
          invoiceTotal
          vat
          createdAt
        }
      }
    }
  }
`
