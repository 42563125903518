import { useQuery } from '@apollo/client'
import {
  InfoAlert,
  InsightsDateNavigator,
  Typography,
} from '@getjelly/jelly-ui'
import { format, isBefore } from 'date-fns'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { spendInsights } from './graphql'
import { SpendGraphs } from './SpendGraphs'
import { SpendList } from './SpendList'

import { Query, QuerySpendInsightsArgs } from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../components'
import { CalendarPeriodSelector } from '../../../components/Insights/CalendarPeriodSelector'
import { useSyncedDatePeriod } from '../../../hooks/useSyncedDatePeriod'
import { routes } from '../../../routes/Paths'
import { percentageChange, toUTCStartOfDay } from '../../../utils'

export function SpendMain() {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const {
    state: { startDate, endDate, periodEnum, nextStart, previousStart },
    setStartDateParam,
    setPeriodParam,
  } = useSyncedDatePeriod()

  const { data, loading } = useQuery<
    { spendInsights: Query['spendInsights'] },
    QuerySpendInsightsArgs
  >(spendInsights, {
    skip: !selectedKitchen,
    variables: {
      kitchenId: selectedKitchen?.id || 0,
      period: periodEnum,
      start: toUTCStartOfDay(startDate),
    },
  })

  const invoiceTotal = useMemo(() => {
    if (!data?.spendInsights) return '--'
    return data.spendInsights.totalSpend.toLocaleString(undefined, {
      currency: 'GBP',
      style: 'currency',
    })
  }, [data])

  const pctChange = useMemo(() => {
    if (!data?.spendInsights) return 0

    return percentageChange(
      data.spendInsights.previousSpend,
      data.spendInsights.totalSpend,
    )
  }, [data])

  const dateText = {
    month: format(startDate, 'MMMM yyy'),
    quarter: format(startDate, 'qqq yyyy'),
    week: `${format(startDate, 'd MMM')} - ${format(endDate, 'd MMM')}`,
  }[periodEnum]

  const pendingInvoices = useMemo(() => {
    if (!data?.spendInsights.totalInvoicesNeedAttention) {
      return 0
    }

    return data.spendInsights.totalInvoicesNeedAttention
  }, [data])

  return (
    <>
      <div className="h-12 flex items-center bg-primary-50">
        <CalendarPeriodSelector value={periodEnum} onChange={setPeriodParam} />
      </div>

      {!!pendingInvoices && (
        <InfoAlert
          text={
            pendingInvoices > 1
              ? `Spend might be inaccurate because there are ${pendingInvoices} documents needing approval. Tap here to approve them.`
              : `Spend might be inaccurate because there is ${pendingInvoices} document needing approval. Tap here to approve it.`
          }
          onClick={() =>
            navigate(`${routes.Spending}/${routes.Invoice}/${routes.History}`)
          }
        />
      )}

      <InsightsDateNavigator
        heading={dateText}
        title={invoiceTotal}
        caption="(excl. VAT)"
        subtitle={
          !isFinite(pctChange) || !data?.spendInsights ? (
            ''
          ) : (
            <div className="flex space-x-1">
              <Typography style="subtitle1">
                {Math.round(pctChange)}%
              </Typography>

              <Typography style="body2">vs prior {periodEnum}</Typography>
            </div>
          )
        }
        forwardDisabled={isBefore(new Date(), nextStart)}
        back={() => setStartDateParam(previousStart.toISOString())}
        forward={() => setStartDateParam(nextStart.toISOString())}
      />

      {loading && !data?.spendInsights ? (
        <Loader />
      ) : (
        <>
          <SpendGraphs
            periodEnum={periodEnum}
            startDate={startDate}
            spendData={data?.spendInsights.spendData || []}
          />

          <SpendList spendTotals={data?.spendInsights.spendTotal || []} />
        </>
      )}
    </>
  )
}
