import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Button, Modal, Typography } from '@getjelly/jelly-ui'
import { IconX } from '@tabler/icons-react'
import { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import {
  Ingredient,
  Mutation,
  MutationDeleteOneIngredientArgs,
  QueryIngredientNodeArgs,
  Status,
} from 'api'
import { NewLayout } from 'components/newUi'
import { routes } from 'routes/Paths'
import {
  deleteOneIngredient,
  relatedItemsQuery,
} from 'screens/Products/PriceHistory/graphql'
import { ViewProduct } from 'screens/Products/View'

import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { errorToast, successToast } from '../../components/toasts'

const EditButton = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <Button
      onClick={() => navigate(`${routes.Product}${routes.Update}/${id}`)}
      label="Edit Product"
      className="w-full"
    />
  )
}

const DeleteButton = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { cache } = useApolloClient()

  const { data } = useQuery<
    {
      ingredientNode: Ingredient
    },
    QueryIngredientNodeArgs
  >(relatedItemsQuery, {
    variables: {
      cursor: id,
    },
  })

  const name = data?.ingredientNode.product.name ?? ''

  const [deleteIngredient] = useMutation<
    {
      deleteOneIngredient: Mutation['deleteOneIngredient']
    },
    MutationDeleteOneIngredientArgs
  >(deleteOneIngredient, {
    onCompleted: () => {
      successToast(`${name} has been deleted`)

      const normalizedId = cache.identify({
        __typename: 'Ingredient',
        id: data?.ingredientNode.id,
      })
      cache.evict({ id: normalizedId })
      cache.gc()

      navigate(-1)
    },
    onError: (error) => {
      errorToast(String(error))
      console.error(error)
    },
  })

  const handleDelete = () => {
    if (!data?.ingredientNode.id) return

    deleteIngredient({ variables: { data: { id: data?.ingredientNode.id } } })
  }

  const deleteable = data?.ingredientNode.recipeList.nodes.every(
    (n) => n.recipe.status === Status.Inactive,
  )

  if (!deleteable) return null

  return (
    <>
      <div className="px-2">
        <Button
          onClick={() => setShowDeleteModal(true)}
          label="Delete"
          style="delete"
          icon={IconX}
        />
      </div>

      <Modal
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false)
        }}
      >
        <div className="space-y-8">
          <div className="text-center px-2">
            <Typography style="h6" className="text-primary-900">
              Are you sure?
            </Typography>

            <Typography style="caption" className="text-primary-600">
              It’ll be removed from your kitchen and can’t be used in dishes and
              recipes
            </Typography>
          </div>

          <div className="space-y-2">
            <Button
              style="delete"
              onClick={() => {
                setShowDeleteModal(false)
                handleDelete()
              }}
              label="Yes, Delete Product"
              className="w-full"
            />

            <Button
              style="secondary"
              onClick={() => setShowDeleteModal(false)}
              label="Cancel"
              className="w-full"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export const ProductLayout = () => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  return (
    <>
      <NewLayout
        onBack={() => navigate(-1)}
        title="Product"
        bottomContent={
          selectedKitchen?.userPermissions?.includes('update-product') ? (
            <div className="flex justify-center px-2 py-4">
              <EditButton />
            </div>
          ) : undefined
        }
        rightContent={
          selectedKitchen?.userPermissions?.includes('delete-product') ? (
            <DeleteButton />
          ) : undefined
        }
      />
      <ViewProduct />

      <div className="flex-1 flex flex-col overflow-auto w-full">
        <Outlet />
      </div>
    </>
  )
}
