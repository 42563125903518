import { gql } from '@apollo/client'

export const invoiceSummaryUpdateMutation = gql`
  mutation invoiceSummaryUpdate(
    $id: Int!
    $documentType: DocumentType!
    $invoiceDate: Date!
    $invoiceId: String
    $invoiceTotal: Float!
    $vat: Float!
  ) {
    invoiceSummaryUpdate(
      id: $id
      documentType: $documentType
      invoiceDate: $invoiceDate
      invoiceId: $invoiceId
      invoiceTotal: $invoiceTotal
      vat: $vat
    ) {
      id
    }
  }
`
